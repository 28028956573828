import {
    SupportedToken,
    type BlockchainConfig,
    type BlockchainService,
    type Transaction,
} from '../../../types'
import {
    createApproveInstruction,
    createTransferInstruction,
    getAccount,
    getAssociatedTokenAddress,
    TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import * as web3 from '@solana/web3.js'
import { ethers } from 'ethers'
import { getParsedAmount } from '../../../utils'
import { TransactionMessage, VersionedTransaction } from '@solana/web3.js';

export class SolanaProvider implements BlockchainService {
    baanxAddress: web3.PublicKey
    connection: web3.Connection
    tokenContract
    tokenDecimals
    provider
    blockchainConfig: BlockchainConfig

    constructor(provider: any, blockchainConfig: BlockchainConfig) {
        this.connection = new web3.Connection(
            blockchainConfig.jsonRpcUrl,
            'confirmed'
        )
        this.blockchainConfig = blockchainConfig
        this.baanxAddress = new web3.PublicKey(blockchainConfig.contractAddress)
        this.tokenContract = Object.keys(blockchainConfig.token).reduce<
            Partial<Record<SupportedToken, string>>
        >(
            (acc, cur) => ({
                ...acc,
                [cur]: blockchainConfig.token[cur].address,
            }),
            {}
        )
        this.provider = provider
        this.tokenDecimals = {} satisfies Partial<
            Record<SupportedToken, number>
        >
    }

    decimals(tokenType: SupportedToken): number {
        return this.tokenDecimals[tokenType]
    }

    async payment(
        amount: string,
        tokenType: SupportedToken
    ): Promise<Transaction> {
        if (tokenType === SupportedToken.ETH)
            throw Error('Not supported')

        const sourcePubkey = this.provider.publicKey
        const userAta = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            sourcePubkey
        )

        const destAta = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            new web3.PublicKey(this.blockchainConfig.paymentAddress)
        )

        const decimals = this.tokenDecimals[tokenType]
        const parsedAmount = getParsedAmount(amount, decimals)
        const instruction = createTransferInstruction(
            userAta,
            destAta,
            sourcePubkey,
            parsedAmount,
            [],
            TOKEN_PROGRAM_ID
        )

        const txInstruction = new web3.Transaction().add(instruction)

        const { blockhash } = await this.connection.getLatestBlockhash()
        txInstruction.recentBlockhash = blockhash
        txInstruction.feePayer = this.provider.publicKey

        let hash = "";
        try {
          const tx = await this.provider.signAndSendTransaction(txInstruction, {
            preflightCommitment: "processed",
          });
          hash = tx.signature;
        } catch (error: any) {
          // Handle providers that do not support signAndSendTransaction (TrustWallet on Mobile @ WalletConnect - via QrCode)
          if (error.message === "Unknown method(s) requested") {
            hash = await this.provider.sendTransaction(
              txInstruction,
              this.connection,
              {
                preflightCommitment: "processed",
              }
            );
          } else throw error;
        }
        return {
            hash,
            wait: async () => {
                const latestBlockHash =
                    await this.connection.getLatestBlockhash()
                await this.connection.confirmTransaction({
                    blockhash: latestBlockHash.blockhash,
                    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
                    signature: hash,
                })
                return { hash }
            },
        }
    }

    async init(): Promise<void> {
        const tokenKeys = Object.keys(this.tokenContract)
        for (const tokenType of tokenKeys) {
            const tokenSupply = await this.connection.getTokenSupply(
                new web3.PublicKey(this.tokenContract[tokenType])
            )
            this.tokenDecimals[tokenType] = tokenSupply.value.decimals
        }
    }

    async withdraw(
        _sourceAddress: string,
        _amount: string
    ): Promise<Transaction> {
        return await Promise.resolve({ hash: 'hash' })
    }

    async waitForTransaction(_hash: string): Promise<void> {}

    async getBalance(address: string): Promise<string> {
        const userKey = new web3.PublicKey(address)
        const balance = await this.connection.getBalance(userKey)
        return ethers.formatUnits(balance, 6)
    }

    async getAllowance(
        address: string,
        tokenType: SupportedToken
    ): Promise<string> {
        const ata = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            new web3.PublicKey(address)
        )
        const ataInfo = await getAccount(this.connection, ata)

        const amount = ataInfo.delegatedAmount
        const decimals = this.tokenDecimals[tokenType]
        const allowance = ethers.formatUnits(amount, decimals)

        return allowance
    }

    async approve(
        amount: string,
        tokenType: SupportedToken,
        // 
        walletName?: string
    ): Promise<Transaction> {
        const sourcePubkey = this.provider.publicKey
        const userAta = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            sourcePubkey
        )
        const decimals = this.tokenDecimals[tokenType]

        const parsedAmount = getParsedAmount(amount, decimals)
        const [pdaDelegate] = await web3.PublicKey.findProgramAddress(
            [],
            this.baanxAddress
        )

        const instruction = createApproveInstruction(
            userAta,
            pdaDelegate,
            sourcePubkey,
            parsedAmount
        )

        const txInstruction = new web3.Transaction().add(instruction)
        const { blockhash } = await this.connection.getLatestBlockhash()
        txInstruction.recentBlockhash = blockhash
        txInstruction.feePayer = this.provider.publicKey

        let hash = "";

        // Phantom direct integration only supports signAndSendTransaction
        if (!this.provider.sendTransaction) {
            const tx = await this.provider.signAndSendTransaction(txInstruction, {
                preflightCommitment: "processed",
            });
            hash = tx.signature;
        }
        else {
            const isVersioned = walletName === 'Exodus Mobile'
            const txMessage = new TransactionMessage({
                payerKey: sourcePubkey,
                recentBlockhash: (await this.connection.getLatestBlockhash()).blockhash,
                instructions: [instruction], // Add your instruction here
            }).compileToV0Message(); // Compile to version 0 message (default version)

            // Create a versioned transaction
            const versionedTx = new VersionedTransaction(txMessage);
            // TODO: Exodus Wallet only supports Solana VersionedTransactions, but TrustWallet only supports Legacy transactions
            hash = await this.provider.sendTransaction(
                isVersioned? versionedTx : txInstruction, this.connection, {
                preflightCommitment: "processed",
            });
        }
        return {
            hash,
            wait: async () => {
                const latestBlockHash =
                    await this.connection.getLatestBlockhash()
                await this.connection.confirmTransaction({
                    blockhash: latestBlockHash.blockhash,
                    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
                    signature: hash,
                })
                return { hash }
            },
        }
    }

    async balanceOf(
        address: string,
        tokenType: SupportedToken
    ): Promise<string> {
        const userKey = new web3.PublicKey(address)
        const keyAta = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            userKey
        )
        const balance = await getAccount(this.connection, keyAta)
        const decimals = this.decimals(tokenType)

        return ethers.formatUnits(balance.amount, decimals)
    }

    async validateCurrency(tokenType: SupportedToken): Promise<boolean> {
        const ata = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            new web3.PublicKey(this.provider.publicKey)
        )
        try {
            await getAccount(this.connection, ata)
            return true
        } catch (error) {
            // Ata does not exist;
            return false
        }
    }

    async signTypedData(): Promise<string> {
        throw Error('Not Supported')
    }

    async signMessage(message: string): Promise<string> {
        return this.provider.signMessage(
            new TextEncoder().encode(message),
            'utf8'
        )
    }
}
