import { Route, Routes } from 'react-router-dom'
import { NavigateLocations } from './types'
import { type ReactElement } from 'react'

import Swap from './feature/swap/Swap'
import ConnectWallet from './feature/connect-wallet/ConnectWallet'
import Sign from './feature/sign/Sign'
import AnrkMock from './feature/anrk-mock/AnrkMock'
import UIContainer from './components/layout/UIContainer'
import FoxContainer from './feature/fox/FoxContainer'
import MetamaskSdk from './feature/test-metamask-sdk/MmSdk'

function Router(): ReactElement {


    const swapScreen = (
        <UIContainer>
            <Swap />
        </UIContainer>
    )

    const connectWalletScreen = 
        <UIContainer>
            <ConnectWallet />
        </UIContainer>

    const signScreen = (
        <UIContainer>
            <Sign />
        </UIContainer>
    )

    const anrkMockScreen = (
            <AnrkMock />
    )

    // Naming convention:
    // /some-url -> SOME_URL
    // /sign/verify -> SIGN_VERIFY
    return (
        <Routes>
            <Route path={NavigateLocations.FOX_APP_MOCK}             element={anrkMockScreen} />
            <Route path={NavigateLocations.CONNECT_WALLET}           element={connectWalletScreen} />
            <Route path={NavigateLocations.DELEGATE_SWAP}            element={swapScreen} />
            <Route path={NavigateLocations.SIGN}                     element={signScreen} />
            <Route path={NavigateLocations.FOX_DELEGATE}             element={<FoxContainer />} />
            <Route path={NavigateLocations.SDK_TEST}                 element={<MetamaskSdk/>} />
        </Routes>
    )
}

export default Router
