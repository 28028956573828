import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import {useApp} from '../../hooks/useApp'
import {useModal} from '../../contexts/ModalContext'
import {ErrorMessage, getErrorMessage, handleError, PostErrorMessage, PostOperationType,} from '../../error'
import {useSwapToken, useToken, useWalletToken,} from '@baanx/common/network/api/token'
import {ANRK_SWAP_CURRENCY_MAP, AppMode, SupportedBlockchain, WALLET_MAP,} from '../../types'
import usePostMessage from '../../hooks/usePostMessage'
import config from '../../config'
import DelegateSwap from './DelegateSwap'
import {type SwapData} from '@baanx/domain'

const Swap = () => {
    const { showPersistentModal } = useModal()
    const [swapInfo, setSwapInfo] = useState<SwapData>()

    const {
        setAppMode,
        setSelectedNetwork,
        setSelectedCurrency,
        setSelectedWallet,
        setUserId,
        blockchain,
        selectedNetwork,
        selectedWallet,
        selectedCurrency,
        userId,
    } = useApp(SupportedBlockchain.ETHEREUM)

    const location = useLocation()
    const [address, setAddress] = useState<string>('')

    const swapToken = new URLSearchParams(location.search).get('swapToken')
    const userToken = new URLSearchParams(location.search).get('userToken')

    const { refetch: getWalletToken } = useWalletToken(config)
    const { refetch: getSwapToken, isFetched } = useSwapToken(
        config,
        swapToken as string
    )
    const { refetch: getUserInfo } = useToken(config, {
        token: userToken as string,
    })

    const { postErrorMessage } = usePostMessage(
        PostOperationType.DELEGATE_FUNDS
    )

    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setAppMode(AppMode.SWAP)

        if (isFetched) return
        setAppMode(AppMode.SWAP)

        // validate swap token
        if (!swapToken) {
            showPersistentModal('Error', ErrorMessage.INVALID_LINK)
            return
        }

        void (async () => {
            try {
                const { data: swapData } = await getSwapToken()
                const { data: walletData } = await getWalletToken()

                // Handle registered user
                if (userToken) {
                    const { data: userData } = await getUserInfo()
                    if (!userData) {
                        postErrorMessage(PostErrorMessage.USER_DATA)

                        throw Error(ErrorMessage.USER_DATA_ERROR)
                    }
                    setUserId(userData.userId)
                }

                if (!swapData) {
                    postErrorMessage(PostErrorMessage.SWAP_DATA)

                    throw Error(ErrorMessage.SWAP_DATA_ERROR)
                }
                setSelectedNetwork(
                    swapData.from.blockchain
                )
                setSelectedCurrency(swapData.from.token)

                if (!walletData) {
                    postErrorMessage(PostErrorMessage.WALLET_CONNECTION_ERROR)

                    return
                }
                if (
                    !ANRK_SWAP_CURRENCY_MAP[swapData.from.blockchain].includes(
                        swapData.from.token
                    )
                ) {
                    showPersistentModal(
                        'Error',
                        `Token ${swapData.from.token.toUpperCase()} not supported`
                    )
                    return
                }

                if (
                    !WALLET_MAP[swapData.from.blockchain].includes(
                        walletData.wallet.provider
                    )
                ) {
                    showPersistentModal(
                        'Error',
                        `Wallet ${walletData.wallet.provider} not supported`
                    )
                }


                setAddress(walletData.wallet.address)
                setSwapInfo(swapData)
                setSelectedWallet(walletData.wallet.provider)
                setIsLoading(false)
            } catch (error: any) {
                showPersistentModal('Error', getErrorMessage(error))
                handleError(error)
            }
        })()
    }, [getSwapToken, getUserInfo, getWalletToken, isFetched, postErrorMessage, setAppMode, setSelectedCurrency, setSelectedNetwork, setSelectedWallet, setUserId, showPersistentModal, swapToken, userToken])
    console.log(address, swapInfo)
    return (
        (isLoading && <Loading />) ||
         (
            <DelegateSwap
                selectedCurrency={selectedCurrency}
                selectedWallet={selectedWallet}
                selectedNetwork={selectedNetwork}
                blockchain={blockchain}
                userId={userId}
                address={address}
            ></DelegateSwap>
        ))
    
}

export default Swap
