import {
    getBlockchainService,
    getChainIdByName,
} from '@baanx/common/network/blockchain/config'
import { useSDK } from '@metamask/sdk-react'
import config from '../../config'
import { SupportedBlockchain, SupportedToken } from '@baanx/blockchain-config'
import { Alert, Button, Grid, Typography } from '@mui/material'
import { isDesktop } from 'react-device-detect'
import { useState } from 'react'
const MetamaskSdk = () => {
    const { sdk, connected, account, balance } = useSDK()
    const [severity, setSeverity] = useState<'success'|'error'>()
    const [errorMessage, setErrorMessage] = useState<string>()


    const showNotification = (type: 'success' | 'error', message: string) => {
        setSeverity(type)
        setErrorMessage(message)
        setInterval(()=>{
            setSeverity(undefined)
            setErrorMessage('')
        }, 5000)
    }
    const disconnectHandler = async () => {
        try {
            await sdk?.disconnect()
        } catch (error: any) {
            console.error('Error occurred while disconnecting: ', error)
        }
    }
    const connectHandler = async () => {
        try {
            if (isDesktop) {
                await sdk?.connect()
                showNotification('success', 'Connected successfully')
                return
            }
            const switchChainRPC = {
                method: 'wallet_switchEthereumChain',
                params: [
                    {
                        chainId: getChainIdByName(
                            SupportedBlockchain.LINEA,
                            config
                        ),
                    },
                ],
            }
            await sdk?.connectWith(switchChainRPC)
            showNotification('success', 'Connected successfully')

        } catch (error: any) {
            showNotification('error', `Error occurred while connecting: ${error?.message}`)
        }
    }

    const delegateHandler = async () => {
        if (!sdk?.getProvider()) return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        try {
            const provider = await getBlockchainService(
                SupportedBlockchain.LINEA,
                sdk?.getProvider() as any,
                false,
                config
            )

            await sdk?.getProvider()?.request({
                method: 'wallet_switchEthereumChain',
                params: [
                    {
                        chainId: getChainIdByName(
                            SupportedBlockchain.LINEA,
                            config
                        ),
                    },
                ],
            })
            const tx = await provider.approve('1', SupportedToken.USDC)

            await tx?.wait?.()
            showNotification('success', 'Delegated successfully')   
        } catch (error: any) {
            showNotification('error', `Error occurred while delegating: ${error?.message}`)
        }
    }

    return (
        <Grid container alignContent={'center'} textAlign={'center'}>
            <Grid item xs={12}>
                {severity && <Alert severity={severity}>{errorMessage}</Alert>}
            </Grid>
            <Grid item xs={12}>
                {!connected && (
                    <Button fullWidth onClick={connectHandler}>
                        Connect to MM
                    </Button>
                )}
            </Grid>
            <Grid item xs={12}>
                {connected && (
                    <Button fullWidth onClick={disconnectHandler}>
                        Disconnect
                    </Button>
                )}
            </Grid>
            <Grid item xs={12}>
                {account && (
                    <Typography variant="h5" sx={{ wordBreak: 'break-word' }}>
                        Account: {account}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                {balance && (
                    <Typography variant="h5">Balance: {balance}</Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                {connected && (
                    <Button onClick={delegateHandler}> Delegate USDC</Button>
                )}
            </Grid>
        </Grid>
    )
}

export default MetamaskSdk
