import { Button, Grid, IconButton, Stack, Typography } from '@mui/material'
import { ReactComponent as Wallet } from '../../assets/wallet.svg'
import ArrowBack from '@mui/icons-material/ArrowBack'
import {
    AppMode,
    type ConnectAddress,
    type SupportedWallet,
    WALLET_MOBILE_COMPATIBILITY,
} from '../../types'
import { WALLET_MAP } from '../../types'
import { Wallets } from '../blockchain/Utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { ResultModalType, useModal } from '../../contexts/ModalContext'
import { useAddress } from '@baanx/common/network/api/address'
import { usePostWalletToken } from '@baanx/common/network/api/token'
import {
    ErrorMessage,
    PostErrorMessage,
    PostOperationType,
    handleError,
} from '../../error'
import { type ReactElement } from 'react'
import usePostMessage from '../../hooks/usePostMessage'
import { isMobile } from 'react-device-detect'
import config from '../../config'

const ConnectUserWallet = ({
    selectedNetwork,
    setSelectedWallet,
    blockchain,
    appMode,
}: any): ReactElement => {

    const { setAddresses, getAccount } = blockchain
    const { toggleResult } = useModal()
    const navigate = useNavigate()
    const { mutateAsync: postWalletToken } = usePostWalletToken(config)
    const { state } = useLocation()
    const { postErrorMessage, postSuccessMessage } = usePostMessage(
        PostOperationType.WALLET_CONNECT
    )

    const handleNavigation = async (wallet: SupportedWallet): Promise<void> => {
        if (wallet === 'metamask' ) {        
            await blockchain.connectMetamask(selectedNetwork)
         }
        if (appMode === AppMode.CONNECT_WALLET) {
            if (selectedNetwork == null) return
            const address = await getAccount(
                selectedNetwork,
                wallet,
                false,
                state?.walletChange || appMode === AppMode.CONNECT_WALLET
            ).catch(() => {
                postErrorMessage(PostErrorMessage.WALLET_CONNECTION_ERROR, ErrorMessage.WALLET_CONNECTION_REJECTED)
            })

            if (!address) return
            try {
                const walletInfo = await postWalletToken({
                    address,
                    provider: wallet,
                    index: blockchain.ledgerIndex,
                })

                if (!walletInfo) throw Error('Could not save wallet info')

                setSelectedWallet(wallet)

                postSuccessMessage({ token: walletInfo.token })
                return
            } catch (error) {
                toggleResult(
                    true,
                    'Error',
                    'Error while saving information',
                    ResultModalType.ERROR
                )
                postErrorMessage(
                    PostErrorMessage.WALLET_CONNECTION_ERROR,
                    error
                )
                handleError(error)
                return
            }
        }
        const { data: addresses } = await getUserAddresses()
        setAddresses(addresses as ConnectAddress[])
    }
    const { refetch: getUserAddresses } = useAddress(config)

    const onClickHandler = async (wallet: SupportedWallet): Promise<void> => {
        
        let validationResult: undefined | boolean
        try {
            validationResult = await Wallets[wallet].validator(
            )
        } catch (error) {
            toggleResult(
                true,
                'Error',
                'An error occurred while validating the wallet.',
                ResultModalType.ERROR
            )
            return
        }

        if (!validationResult) {
            const errorMessage = `Make sure ${wallet} is installed and selected as the default wallet in your browser.`

            toggleResult(
                true,
                `${
                    wallet.charAt(0).toUpperCase() + wallet.slice(1)
                } not detected`,
                errorMessage,
                ResultModalType.ERROR,
            )
            return
        }

        setSelectedWallet(wallet)
        void handleNavigation(wallet)
    }

    const goBackHandler = (): void => {
        navigate(-1)
    }
    return (
        <Grid xs={12} item>
            <Grid container justifyContent="flex-start" p={2}>
                <Grid item>
                    {![
                        AppMode.SWAP,
                        AppMode.CONNECT_WALLET,
                        AppMode.SIGN,
                    ].includes(appMode) && (
                        <IconButton size="large" onClick={goBackHandler}>
                            <ArrowBack />
                        </IconButton>
                    )}
                </Grid>
            </Grid>

            <Grid p={4}>
                <Grid item xs={12} textAlign={'center'}>
                    <Wallet />
                </Grid>

                <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h4">
                        <b>Connect to your wallet</b>
                    </Typography>
                </Grid>

                <Grid item xs={12} textAlign={'center'} m={2}>
                    <Typography variant="body1">
                        Select your wallet provider
                    </Typography>
                </Grid>

                <Stack spacing={2}>
                    {selectedNetwork != null &&
                        WALLET_MAP[selectedNetwork].filter((wallet)=>(!isMobile || WALLET_MOBILE_COMPATIBILITY[wallet])).map(
                            (wallet: SupportedWallet) => (
                                <Grid key={wallet} item xs={12}>
                                    <Button
                                        fullWidth
                                        onClick={onClickHandler.bind(
                                            null,
                                            wallet
                                        )}
                                        startIcon={Wallets[wallet].smallIcon}
                                        key={wallet}
                                        variant="contained"
                                    >
                                        {Wallets[wallet].simpleName}
                                    </Button>
                                </Grid>
                            )
                        )}
                </Stack>
            </Grid>
        </Grid>
    )
}

export default ConnectUserWallet
