import { type SupportedWallet } from '../../types'

const getWindow = () => {
    try {
        // if different domain this line will throw. If no iframe (local) window.top = window.self so it works normally
        if (window.top && (window.top as any)?.ethereum) {
            return window.top as unknown as { ethereum; phantom; providers }
        }
    } catch (e) {
        return window as unknown as { ethereum; phantom; providers }
    }
    return window as unknown as { ethereum; phantom; providers }
}

const windowAsAny = getWindow()

export const WALLET_VALIDATORS: {
    [key in SupportedWallet]: (
        arg1?: any,
        arg2?: any
    ) => boolean | Promise<boolean>
} = {
    metamask: function (): boolean {
        // not used
        return true
    },
    phantom: function (): boolean {
        return windowAsAny.phantom?.solana
    },
}

export const WALLET_PROVIDERS: { [key in SupportedWallet]: any } = {
    metamask: function (): any {
       // not used
    },
    phantom: function (): any {
        return windowAsAny.phantom.solana
    },
}
