import { useCallback, useEffect } from 'react'
import {
    getPostMessageError,
    type PostErrorMessage,
    type PostOperationType,
} from '../error'

const usePostMessage = (
    operationType: PostOperationType,
    ignoreErrors?: string[],
    onMessageReceived?: (event: MessageEvent) => void
) => {
    const postErrorMessage = useCallback(
        (
            errorType: PostErrorMessage,
            error?: any
        ) => {
            const errorMessage =
                error?.reason ?? error?.message ?? JSON.stringify(error)
            if (
                error &&
                ignoreErrors?.find((error) => errorMessage?.includes(error))
            ) {
                return
            }
            const postMessage = getPostMessageError(
                operationType,
                errorType,
                error
            )
            console.log('postErrorMessage', postMessage)
            window.parent.postMessage(postMessage, '*')
        },
        [ignoreErrors, operationType]
    )

    const postSuccessMessage = useCallback(
        (data?: any) => {
            const postMessage = {
                type: operationType,
                data: { ...data, status: 'success' },
            }
            console.log('postSuccessMessage', postMessage)
            window.parent.postMessage(postMessage, '*')
        },
        [operationType]
    )

    const postCustomMessage = useCallback((data: { type: string, data: any }) => {
        console.log('postCustomMessage', data)
        window.parent.postMessage(data, '*')
    }, [])

    useEffect(() => {
        if (!onMessageReceived) {
            return
        }
        const listener = (event: MessageEvent) => {
            onMessageReceived?.(event)
        }
        window.addEventListener('message', listener)

        return () => {
            window.removeEventListener('message', listener)
        }

    }, [onMessageReceived])

    return { postErrorMessage, postSuccessMessage, postCustomMessage }
}

export default usePostMessage
