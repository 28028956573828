import { useCallback, useState } from 'react'
import useBlockchain, { type BlockchainHook } from './useBlockchain'
import {
    AppMode,
    SupportedBlockchain,
    SupportedToken,
    SupportedWallet,
    type ApprovalDTO,
    type Transaction,
    STABLE_COINS,
} from '../types'
import { ErrorMessage, Errors, getErrorMessage } from '../error'
import { useToken } from '@baanx/common/network/api/token'
import { useAddress } from '@baanx/common/network/api/address'
import { useAudit } from '@baanx/common/network/api/audit'
import config from '../config'

interface UseApp {
    userId: string
    setUserId: (userId: string) => void
    blockchain: BlockchainHook
    selectedNetwork: SupportedBlockchain
    setSelectedNetwork: (selectedNetwork: SupportedBlockchain) => void
    setSelectedCurrency: (selectedCurrency: SupportedToken) => void
    selectedCurrency: SupportedToken
    appMode: AppMode
    setAppMode: (appMode: AppMode) => void
    selectedWallet: SupportedWallet
    setSelectedWallet: (selectedWallet: SupportedWallet) => void
    loadUserInfo: () => Promise<string>
    auditThenDelegate: (amount: string) => Promise<Partial<Transaction>>
    preferredFiatCurrency: string | undefined
    exchangeRate: number | undefined
    cardUsageTitle: string | undefined
}

export function useApp(blockchainKey: SupportedBlockchain): UseApp {
    const [userId, setUserId] = useState<string>('')
    const [selectedNetwork, setSelectedNetwork] = useState<SupportedBlockchain>(blockchainKey)
    const [selectedCurrency, setSelectedCurrency] = useState<SupportedToken>(SupportedToken.USDC)
    const [appMode, setAppMode] = useState<AppMode>(AppMode.FOX)
    const blockchain = useBlockchain()
    const [selectedWallet, setSelectedWallet] = useState<SupportedWallet>(SupportedWallet.METAMASK)
    const { mutateAsync: sendServerData } = useAudit(config)
    const [preferredFiatCurrency, setPreferredFiatCurrency] = useState<string>()
    const [exchangeRate, setExchangeRate] = useState<any>()
    const [cardUsageTitle, setCardUsageTitle] = useState<string>()
    const tokenUrl = new URLSearchParams(location.search).get('token') ?? ''
    const { refetch: getUserByToken } = useToken(config, { token: tokenUrl })
    const { refetch: getUserAddresses } = useAddress(config)

    const loadUserInfo = useCallback(async () => {
        if (!selectedCurrency || !selectedNetwork || !selectedWallet) throw new Error('Invalid state')
        if (!tokenUrl) {
            throw Error(ErrorMessage.INVALID_LINK)
        }
        let connectedAccount: string
        try {
            connectedAccount = await blockchain.getAccount(
                selectedNetwork,
                selectedWallet
            )
        } catch (error: any) {
            throw Error(
                `${ErrorMessage.WALLET_ERROR}. ${getErrorMessage(error)}`
            )
        }
        if (!connectedAccount) throw new Error('No connected account')

        try {
            const userData = await getUserByToken()
            if (!userData.data?.userId) throw Error('User id is invalid')
            const exchangeRate = userData.data?.exchangeRate
            
            const addresses = await getUserAddresses()
            if (!addresses.data) throw Error('Addresses are invalid')
            const stableAddresses = addresses.data.filter(
                (connect) =>
                    connect.blockchain === selectedNetwork &&
                    STABLE_COINS.includes(connect.currency)
            )
            let favToken = SupportedToken.USDC
            let maxBalance = 0
            await blockchain.refreshConnectionRpc(selectedNetwork)
            for (const address of stableAddresses) {
                const balance = Number(
                    await blockchain.balanceOf(
                        connectedAccount,
                        address.currency
                    )
                )
                if (balance > maxBalance) {
                    favToken = address.currency
                    maxBalance = balance
                }
            }
            await blockchain.refreshConnection(
                selectedNetwork,
                favToken,
                selectedWallet
            )
            setPreferredFiatCurrency(userData.data?.preferredFiatCurrency ?? 'USD')
            setSelectedCurrency(favToken)
            blockchain.setAddresses(addresses.data)
            exchangeRate && setExchangeRate(exchangeRate)
            setCardUsageTitle(userData.data?.cardUsageTitle)
            setUserId(userData.data.userId)

            return connectedAccount
        } catch (error: any) {
            throw Error(`Error while retrieving user info: ${error.message}`)
        }
    }, [blockchain, getUserAddresses, getUserByToken, selectedCurrency, selectedNetwork, selectedWallet, tokenUrl])

    const auditThenDelegate = useCallback(
        async (amount: string) => {
            if (!selectedNetwork || !selectedWallet || !selectedCurrency) throw new Error('Invalid state')
            const address = blockchain.connectedAccount ?? await blockchain.getAccount(
                selectedNetwork,
                selectedWallet,
            )

            const tx = await blockchain.delegateFunds(
                amount,
                selectedCurrency
            )

            const serverData: ApprovalDTO = {
                address,
                blockchain: selectedNetwork,
                currency: selectedCurrency as string,
                amount,
                transaction: { hash: tx.hash ?? 'UNKNOWN' },
            }
            try {
                await sendServerData(serverData)
            } catch (error) {
                throw Error(Errors.APPROVAL_REQUEST_FAILED)
            }
            return tx
        },
        [blockchain, selectedCurrency, selectedNetwork, selectedWallet, sendServerData]
    )

    return {
        userId,
        setUserId,
        blockchain,
        selectedNetwork,
        setSelectedNetwork,
        selectedCurrency,
        setSelectedCurrency,
        appMode,
        setAppMode,
        selectedWallet,
        setSelectedWallet,
        loadUserInfo,
        auditThenDelegate,
        preferredFiatCurrency,
        exchangeRate,
        cardUsageTitle,
    }
}
